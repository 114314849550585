import environmentVars from "../environments/env.json";
import {
  putRequest,
  getRequest,
  postRequest,
  deleteRequest,
  loadSessionItems,
  storeSessionItems,
} from "./fetchCalls";
import { useAsync } from "react-use";
import { useEffect } from "react";
import { usePersistedState } from "./persistedState";

const apiUrl = environmentVars.REACT_APP_SERVICE_API;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const updateUser = async (firstName, lastName, phone) => {
  const body = `{"firstName": "${firstName}", "lastName": "${lastName}", "phoneNumber": "${phone}"}`;
  const userCall = await putRequest(`${apiUrl}/users`, body);
  return userCall;
};

export const updateUserPassword = async (password) => {
  const body = `{"password": "${password}"}`;
  const userCall = await putRequest(`${apiUrl}/users`, body);
  return userCall;
};

export const updateUserRole = async (id, role) => {
  const body = `{"role": "${role}"}`;
  const userCall = await putRequest(`${apiUrl}/users/${id}`, body);
  return userCall;
};

export const updateUserTeam = async (id, team_id) => {
  const body = `{"team_id": "${team_id}"}`;
  const userCall = await putRequest(`${apiUrl}/users/${id}`, body);
  return userCall;
};

export const updateOverrideDealIDs = async (data) => {
  const body = `{"overrideDealIDs": "${data.value}"}`;
  const userCall = await putRequest(`${apiUrl}/users/${data.id}`, body);
  return userCall;
};

export const updateUserEnabled = async (data) => {
  const body = `{"enabled": ${data.checked}}`;
  const userCall = await putRequest(`${apiUrl}/users/${data.id}`, body);
  return userCall;
};

export const getRoleByName = async (role) => {
  const roleRequest = await getRequest(`${apiUrl}/roles/${role}`);
  return roleRequest;
};

export async function getUserByUid(wait = true, refresh = false) {
  if (!refresh) {
    if (wait) {
      await delay(2000);
    }

    const getSessionData = loadSessionItems("userInfo") || [];
    if (getSessionData.length > 0 || Object.keys(getSessionData).length > 0) {
      return getSessionData;
    }
  }

  const result = await getRequest(`${apiUrl}/users`, wait);
  if (result.data.length) {
    const user = result.data[0];
    storeSessionItems("userInfo", user);
    return user;
  }
  return null;
}

export const createProject = async (id, name) => {
  const body = `{
    "hubspotProjectId": "${id}",
    "name": "${name}"
  }`;

  const createProject = await postRequest(`${apiUrl}/projects`, body);
  return createProject;
};

export const getDealById = async (id) => {
  const result = await getRequest(`${apiUrl}/hubspot/deals/${id}`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getAuroraProjects = async (reload = false) => {
  if (!reload) {
    const auroraProjects = loadSessionItems("auroraProjects") || [];
    if (auroraProjects.length > 0 || Object.keys(auroraProjects).length > 0) {
      return auroraProjects;
    }
  }

  const result = await getRequest(`${apiUrl}/aurora/projects`);

  if (result.data) {
    const obj = result.data.projects;
    storeSessionItems("auroraProjects", obj);
    return obj;
  }

  return null;
};

export const getAuroraProject = async (id) => {
  const result = await getRequest(`${apiUrl}/aurora/project/${id}`);

  if (result.data) {
    const obj = result.data.project;
    return obj;
  }

  return null;
};

export const getAuroraProjectConsumption = async (id) => {
  const result = await getRequest(`${apiUrl}/aurora/consumptionProfile/${id}`);

  if (result.data) {
    const obj = result.data.consumption_profile;
    return obj;
  }

  return null;
};

export const getProposalMessage = async () => {
  const result = await getRequest(`${apiUrl}/defaultItems`);

  if (result.data) {
    // const proposalMessage = result?.data.find(
    //   (x) => x.name === "proposal_message"
    // );

    // const obj = proposalMessage;
    const obj = result.data;
    return obj;
  }

  return null;
};

export const updateProposalMessage = async (data) => {
  const body = `{"${data.name}": "${data.value}"}`;
  const result = await putRequest(`${apiUrl}/defaultItems/${data.id}`, body);

  return result;
};

export const updateDefaultItem = async (data) => {
  const body = `{"${data.name}": "${data.value}"}`;
  const result = await putRequest(`${apiUrl}/defaultItems/${data.id}`, body);

  return result;
};

export const getDocumentDefaultValues = async () => {
  const result = await getRequest(`${apiUrl}/defaultItems`);

  if (result.data) {
    const documentValues = result?.data.filter((x) =>
      x?.name.startsWith("documents:")
    );

    const obj = documentValues;
    // const obj = result.data;
    return obj;
  }

  return null;
};

export const getAuroraDesignAssets = async (id) => {
  const result = await getRequest(`${apiUrl}/aurora/design/${id}/assets`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getAuroraProjectDesigns = async (id) => {
  const result = await getRequest(`${apiUrl}/aurora/designs/${id}`);

  if (result.data) {
    const obj = result.data.designs;
    return obj;
  }

  return null;
};

export const getAuroraDesignSummary = async (id) => {
  const result = await getRequest(`${apiUrl}/aurora/design/${id}/summary`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const deleteProposal = async (id) => {
  const result = await deleteRequest(`${apiUrl}/proposals/${id}`);
  if (result) {
    return result.data;
  }

  return null;
};

export const deleteBankRate = async (id) => {
  const result = await deleteRequest(`${apiUrl}/bank-rates/${id}`);
  if (result) {
    return result.data;
  }

  return null;
};

export const getBankRates = async () => {
  const result = await getRequest(`${apiUrl}/bank-rates`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getRoles = async () => {
  const result = await getRequest(`${apiUrl}/roles`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getTeams = async () => {
  const result = await getRequest(`${apiUrl}/teams`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getUsers = async () => {
  const result = await getRequest(`${apiUrl}/users/all`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getResources = async () => {
  const result = await getRequest(`${apiUrl}/resources`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getEnabledBankRates = async () => {
  const result = await getRequest(`${apiUrl}/bank-rates/enabled`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getTPOProducts = async () => {
  const result = await getRequest(`${apiUrl}/tpo-products`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const updateBankRate = async (data) => {
  const body = `{"${data.name}": ${data.checked}}`;
  return await putRequest(`${apiUrl}/bank-rates/${data.id}`, body);
};

export const updateFinProduct = async (data) => {
  const body = `{"${data.name}": ${data.value}}`;
  return await putRequest(`${apiUrl}/bank-rates/${data.id}`, body);
};

export const updateDealerFee = async (data) => {
  return await updateFinProduct(data);
};

export const updatePaymentCap = async (data) => {
  return await updateFinProduct(data);
};

export const updateLoanCap = async (data) => {
  return await updateFinProduct(data);
};

export const updateRoleValue = async (data) => {
  let role = data?.additionalVars?.roles.find((item) => item.id === data.id);
  if (role?.permissions) {
    if (data.checked) {
      role.permissions = role.permissions.concat(data?.name);
    }

    if (!data.checked) {
      role.permissions = role.permissions.filter((perm) => perm !== data.name);
    }
  }
  const body = `${JSON.stringify(role)}`;
  const result = await putRequest(`${apiUrl}/roles/${data.id}`, body);

  return result;
};
export const createRole = async (name) => {
  const body = `{
    "name": "${name}",
    "permissions": []
  }`;

  const createRole = await postRequest(`${apiUrl}/roles`, body);
  return createRole;
};

export const createUser = async (email, firstName, lastName, role) => {
  const body = `{
    "email": "${email}",
    "firstName": "${firstName}",
    "lastName": "${lastName}",
    "role": "${role}"
  }`;

  const createUser = await postRequest(`${apiUrl}/users`, body);
  return createUser;
};

export const createFinancingProduct = async (
  bank,
  apr,
  dealerFee,
  paymentFactor1,
  paymentFactor19,
  breakoutPaymentFactor,
  paymentCap,
  loanCap,
  term,
  enabled
) => {
  const body = `{
    "bank": "${bank}",
    "apr": ${apr},
    "dealerFee": ${dealerFee},
    "paymentFactor1": ${paymentFactor1},
    "paymentFactor19": ${paymentFactor19},
    "breakoutPaymentFactor": ${breakoutPaymentFactor},
    "paymentCap": ${paymentCap},
    "loanCap": ${loanCap},
    "term": ${term},
    "enabled": ${enabled}
  }`;

  const createFinancingProduct = await postRequest(
    `${apiUrl}/bank-rates`,
    body
  );
  return createFinancingProduct;
};

export const createProposal = async (
  id,
  design,
  bankRate,
  tpoProduct,
  baseline,
  leadFee,
  commission,
  roofPrice,
  treePrice,
  otherTotal,
  otherPPW,
  message,
  imageUrl,
  utility,
  utilityRate,
  type,
  energyConsultantName,
  energyConsultantEmail,
  energyConsultantPhone
) => {
  let body = `{
    "projectId": "${id}",
    "auroraId": "${design?.auroraId}",
    "design": "${design?.id}",
    "designName": "${design?.name}",
    "systemSize": ${design?.systemSize},
    "baseline": ${baseline},
    "leadFee": ${leadFee},
    "commission": ${commission},
    "roofPrice": ${roofPrice},
    "treePrice": ${treePrice},
    "otherTotal": ${otherTotal},
    "otherPPW": ${otherPPW},
    "message": "${message}",
    "imageUrl": "${imageUrl}",
    "utility": "${utility}",
    "type": "${type}",
    "energyConsultantName": "${energyConsultantName}",
    "energyConsultantEmail": "${energyConsultantEmail}",
    "energyConsultantPhone": "${energyConsultantPhone}",
    "utilityRate": ${utilityRate}`;

  if (bankRate) {
    body =
      body +
      `,"bankApr": ${bankRate?.apr},
    "bankName": "${bankRate?.bank}",
    "bankPaymentFactor1": ${bankRate?.paymentFactor1},
    "bankPaymentFactor19": ${bankRate?.paymentFactor19},
    "bankTerm": ${bankRate?.term},
    "bankDealerFee": ${bankRate?.dealerFee}
    `;
  }
  body = body + `}`;

  const createProposal = await postRequest(`${apiUrl}/proposals`, body);
  return createProposal;
};

export const getProposals = async (id) => {
  const result = await getRequest(`${apiUrl}/proposals/${id}`);

  if (result.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getEquipment = async () => {
  const result = await getRequest(`${apiUrl}/inverter`);

  if (result?.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};

export const getEquipmentByName = async (name) => {
  const result = await getRequest(`${apiUrl}/inverter/name/${name}`);

  if (result?.data) {
    const obj = result.data;
    return obj;
  }

  return null;
};
