import React from "react";
import { add } from "date-fns";

export function usePersistedState(key, expires, initialState) {
  var _a = (0, React.useState)(function () {
      try {
        var cachedItem = localStorage.getItem(key);
        if (cachedItem) {
          // If we have a cached item, make sure it isn't expired
          var cacheEntry = JSON.parse(cachedItem);
          if (cacheEntry.expiration < new Date().getTime()) {
            // Expired; remove from cache
            localStorage.removeItem(key);
          } else {
            // Not expired; return
            return cacheEntry.entry;
          }
        }
        // Default to returning the provided `initialState`
        return initialState;
      } catch (error) {
        localStorage.removeItem(key);
        return initialState;
      }
    }),
    currentState = _a[0],
    setCurrentState = _a[1];

  var setAndPersistState = function (value) {
    try {
      var valueToStore =
        value instanceof Function ? value(currentState) : value;
      setCurrentState(valueToStore);

      var nextExpire = add(new Date(), expires).getTime();
      var cachedItem = localStorage.getItem(key);
      if (cachedItem) {
        var cacheEntry = JSON.parse(cachedItem);
        if (cacheEntry.expiration > new Date().getTime()) {
          nextExpire = cacheEntry.expiration;
        }
      }

      localStorage.setItem(
        key,
        JSON.stringify({
          entry: valueToStore,
          expiration: nextExpire,
        })
      );
    } catch (error) {
      localStorage.removeItem(key);
    }
  };
  return [currentState, setAndPersistState];
}
