export const UserRole = {
  Admin: "Admin",
  SuperAdmin: "Super Admin",
  Employee: "Employee",
  SalesRep: "SalesRep",
  SalesManager: "Sales Manager",
  AllJobs: "All Jobs",
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyCZiR-g2GRvP-yLAuPDOAfcJ30RrjMk8sM",
  authDomain: "emt-bob.firebaseapp.com",
  // databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: "emt-bob",
  // storageBucket: 'gogo-react-login.appspot.com',
  // messagingSenderId: '216495999563',
};

export const adminRoot = "/app";
export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/#`;
export const servicePath = "https://api.coloredstrategies.com";

export const currentUser = {
  // id: 1,
  // title: 'Sarah Kortney',
  // img: '/assets/img/profiles/l-1.jpg',
  // date: 'Last seen today 15:24',
  // role: UserRole.Admin,
};

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.yellowgranola";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];
